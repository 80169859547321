import React, { useState, useEffect } from "react";
import DashNav from "../../component/DashNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ManageEvent from "../../component/ManageEvent";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const DashboardIndex = () => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate(); // Initialize navigate function
  useEffect(() => {
    // Retrieve username from localStorage
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);
  const handleLogout = () => {
    localStorage.removeItem("x-access-token");
    localStorage.removeItem("username");
    navigate("/");
  };

  return (
    <>
      <DashNav />
      <div className="container-fluid my-3">
        <div className="float-end text-right">
          <DropdownButton
            className="userBtn"
            id="dropdown-basic-button"
            title={
              <span>
                <FontAwesomeIcon icon={faUser} /> {username || "Account"}
              </span>
            }
            variant="dark"
          >
            <Dropdown.Item onClick={handleLogout}>
              <FontAwesomeIcon icon={faRightFromBracket} /> Logout
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="d-flex justify-content-center my-5">
        <div className="d-grid gap-2 col-md-5">
          <a
            href="/add-event"
            className="btnStyles fs-4 fw-bold rounded-2 px-5 py-3 border-0 text-center text-black text-decoration-none"
          >
            Add Event
          </a>
        </div>
      </div>
      <div className="container">
        <div className="mb-5">
          <ManageEvent />
        </div>
      </div>
      <div className="footer dashBoardNav fixed-bottom bg-dark text-center text-white fs-6 mt-5 py-4">
        <div className="container">
          Copyright © 2024 <strong>Thunder Horse</strong>
        </div>
      </div>
    </>
  );
};

export default DashboardIndex;
