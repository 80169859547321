import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Button,
  Toast,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const AddEventRacing = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [trackName, setTrackName] = useState("");
  const [trackLengthOptions, setTrackLengthOptions] = useState([]);
  useEffect(() => {
    // Retrieve track name from localStorage
    const storedTrackName = localStorage.getItem("trackName");
    if (storedTrackName) {
      setTrackName(storedTrackName);
      // Generate track length options based on track name
      generateTrackLengthOptions(storedTrackName);
    }
  }, []);

  const generateTrackLengthOptions = (trackName) => {
    // Logic to generate track length options based on track name
    let options = [];
    if (trackName === "JabelAli") {
      options = [1000, 1200, 1400, 1950];
    } else if (trackName === "KingAbdulAziz") {
      options = [800, 1400, 2100, 2200, 3000];
    } else if (trackName === "Meydan") {
      options = [1200, 1400, 1800, 1950, 2200];
    }
    setTrackLengthOptions(options);
  };

  const showToastMessage = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  const handleSaveAndCreate = (e) => {
    e.preventDefault();
    const form = document.querySelector(".row");
    const isValid = form.checkValidity();

    // Check if all required fields are filled
    const requiredFields = form.querySelectorAll("[required]");
    let allFieldsFilled = true;
    requiredFields.forEach((field) => {
      if (!field.value.trim()) {
        allFieldsFilled = false;
        field.classList.add("is-invalid"); // Mark empty fields as invalid
      }
    });

    if (isValid && allFieldsFilled) {
      setShowConfirmation(true);
    } else {
      // If any required field is empty, show error message
      showToastMessage("Please fill out all required fields.", "error");
      // Also, mark all invalid fields as invalid to display error messages
      requiredFields.forEach((field) => {
        if (!field.value.trim()) {
          field.classList.add("is-invalid");
        }
      });
    }
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleReload = async () => {
    // Prepare the race data
    const event = localStorage.getItem("newEvent");

    const eventData = {
      eventNumber: event,
      raceNumber: parseInt(
        document.getElementById("formGroupRaceNumber").value
      ),
      trackLength: parseInt(
        document.getElementById("formGroupTrackLength").value
      ),
      totalAllowedMembers: parseInt(
        document.getElementById("formGroupTotalAllowedMembers").value
      ),
      reward: {
        coins: parseInt(document.getElementById("completionCoins").value),
        gems: parseInt(document.getElementById("completionGems").value),
        xp: parseInt(document.getElementById("xp").value),
      },
    };

    try {
      // Make the API call
      const token = localStorage.getItem("x-access-token");

      const response = await fetch(
        "https://api.thunderhorse.ae/api/v1/events/races",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify(eventData),
        }
      );
      const responseData = await response.json();
      if (responseData.code === 400) {
        toast.error(responseData.message);
      } else {
        showToastMessage("Event race created successfully", "success");
        // Redirect to dashboard after successful creation
        window.location.href = "/add-event-race";
      }
    } catch (error) {
      console.error("Error creating event race:", error);
      showToastMessage("Failed to create event race", "error");
    }
  };

  const handleProceed = async () => {
    // Prepare the race data
    const event = localStorage.getItem("newEvent");

    const eventData = {
      eventNumber: event,
      raceNumber: parseInt(
        document.getElementById("formGroupRaceNumber").value
      ),
      trackLength: parseInt(
        document.getElementById("formGroupTrackLength").value
      ),
      totalAllowedMembers: parseInt(
        document.getElementById("formGroupTotalAllowedMembers").value
      ),
      reward: {
        coins: parseInt(document.getElementById("completionCoins").value),
        gems: parseInt(document.getElementById("completionGems").value),
        xp: parseInt(document.getElementById("xp").value),
      },
    };

    try {
      // Make the API call
      const token = localStorage.getItem("x-access-token");

      const response = await fetch(
        "https://api.thunderhorse.ae/api/v1/events/races",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify(eventData),
        }
      );
      const responseData = await response.json();
      if (responseData.code === 400) {
        toast.error(responseData.message);
      } else {
        showToastMessage("Event race created successfully", "success");
        // Redirect to dashboard after successful creation
        localStorage.removeItem("newEvent");
        localStorage.removeItem("trackName");
        window.location.href = "/dashboard";
      }
    } catch (error) {
      console.error("Error creating event race:", error);
      showToastMessage("Failed to create event race", "error");
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        className={`toast-${toastType} toast-top-right`}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>

      <h4 className="textGradient fs-1 fw-bold text-center mb-3">
        Add Event Races
      </h4>

      <Form className="row">
        <Form.Group className="mb-3" controlId="formGroupRaceNumber">
          <Form.Label className="text-white fw-bold">
            Race Number{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Please select the race number for the event (1-7).
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-info" />
            </OverlayTrigger>
          </Form.Label>
          {/* <Form.Control
            type="text"
            className="costField"
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
            required
          /> */}
          <Form.Select className="costField" required>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="4">5</option>
            <option value="4">6</option>
            <option value="4">7</option>
          </Form.Select>
        </Form.Group>

        {/* <Form.Group className="mb-3" controlId="formGroupTrackLength">
          <Form.Label className="text-white fw-bold">Track Length</Form.Label>
          <Form.Control
            type="text"
            className="costField"
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
            required
          />
        </Form.Group> */}

        <Form.Group className="mb-3" controlId="formGroupTrackName">
          <Form.Label className="text-white fw-bold">Track Name</Form.Label>
          <Form.Control
            className="costField"
            type="text"
            value={trackName}
            disabled
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGroupTrackLength">
          <Form.Label className="text-white fw-bold">
            Track Length{" "}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Please select the length of track.</Tooltip>}
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-info" />
            </OverlayTrigger>
          </Form.Label>
          <Form.Select className="costField" required>
            {trackLengthOptions.map((option, index) => (
              <option key={index} value={option}>
                {option} meters
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        {/* <Form.Group className="mb-3" controlId="formGroupTotalAllowedMembers">
          <Form.Label className="text-white fw-bold">
            Total Allowed Members
          </Form.Label>
          <Form.Control
            type="text"
            className="costField"
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
            required
          />
        </Form.Group> */}
        <Form.Group className="mb-3" controlId="formGroupTotalAllowedMembers">
          <Form.Label className="text-white fw-bold">
            Number of players in race{" "}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Number of players in race.</Tooltip>}
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-info" />
            </OverlayTrigger>
          </Form.Label>
          <Form.Select className="costField" required>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
          </Form.Select>
        </Form.Group>
        <div className="p-2 my-3">
          <Form.Label className="text-white fw-bold">
            Reward{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Please enter the amount of coins , gems and xp rewarded upon
                  completing the race.
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-info" />
            </OverlayTrigger>
          </Form.Label>
          <div className="formFee">
            <div className="row">
              <Form.Group className="mb-3 col-md-4" controlId="completionCoins">
                <Form.Label className="text-white fw-bold">Coins</Form.Label>
                <Form.Control
                  type="text"
                  className="costField"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 col-md-4" controlId="completionGems">
                <Form.Label className="text-white fw-bold">Gems</Form.Label>
                <Form.Control
                  type="text"
                  className="costField"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 col-md-4" controlId="xp">
                <Form.Label className="text-white fw-bold">Xp</Form.Label>
                <Form.Control
                  type="text"
                  className="costField"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  required
                />
              </Form.Group>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center my-5">
          <div className="d-grid gap-2 col-md-5">
            <button
              onClick={handleSaveAndCreate}
              className="btnStyles text-black fs-4 fw-bold rounded-0 px-5 py-3 border-0 text-center text-decoration-none"
            >
              Save
            </button>
          </div>
        </div>

        <Modal show={showConfirmation} onHide={handleConfirmationClose}>
          <Modal.Header>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to add races to this event?
          </Modal.Body>
          <Modal.Footer className="d-inline-block">
            <Button
              variant="secondary"
              onClick={handleConfirmationClose}
              className="float-start btnStyles text-black fs-6 fw-bold rounded-0 px-3 py-2 border-0 text-center text-decoration-none"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleProceed}
              className="float-end btnStyles text-black fs-6 fw-bold rounded-0 px-3 py-2 border-0 text-center text-decoration-none"
            >
              Save & Finish
            </Button>
            <Button
              variant="secondary"
              onClick={handleReload}
              className="float-end btnStyles text-black fs-6 fw-bold rounded-0 px-3 py-2 border-0 text-center text-decoration-none"
            >
              Save & add more race
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </div>
  );
};

export default AddEventRacing;
