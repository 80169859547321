import React, { useState, useEffect } from "react";
import DashNav from "../../component/DashNav";
import AddEvent from "../../component/AddEvent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { useNavigate } from "react-router-dom"; // Import useNavigate hook
const AddEventPage = () => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate(); // Initialize navigate function
  useEffect(() => {
    // Retrieve username from localStorage
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);
  const handleLogout = () => {
    localStorage.removeItem("x-access-token");
    localStorage.removeItem("username");
    navigate("/");
  };
  return (
    <>
      <DashNav />
      <div className="container-fluid my-3">
        <div className="float-end text-right">
          <DropdownButton
            className="userBtn"
            id="dropdown-basic-button"
            title={
              <span>
                <FontAwesomeIcon icon={faUser} /> {username || "Account"}
              </span>
            }
            variant="dark"
          >
            <Dropdown.Item onClick={handleLogout}>
              <FontAwesomeIcon icon={faRightFromBracket} /> Logout
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="clearfix"></div>
      <AddEvent />
    </>
  );
};

export default AddEventPage;
