/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../../src/img/logo.png";

const DashNav = () => {
  return (
    <div className="dashBoardNav borderBottom">
      <div className="container-fluid">
        <a
          href="/dashboard"
          className="textGradient fs-2 fw-bold text-left d-inline-block my-3"
        >
          <img src={logo} width={100} />
        </a>
      </div>
    </div>
  );
};

export default DashNav;
