/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../img/logo.png";

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://api.thunderhorse.ae/api/v1/user/adminLogin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        }
      );
      const data = await response.json();

      console.log("data::: ", data);
      if (response.ok) {
        if (data.statusCode == 200) {
          localStorage.setItem("x-access-token", data.data.token);
          localStorage.setItem("username", data.data.user.displayName);
          navigate("/dashboard"); // Redirect to dashboard route
          toast.success("Login successful");
        } else {
          toast.error(data.message);
        }
        // If login is successful, save the token to localStorage and redirect to dashboard
      } else {
        // Handle error response
        console.error("Login failed:", data.error);
        toast.error("Login failed: " + data.error);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("Error occurred: " + error.message);
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <div className="d-flex justify-content-center">
        <div className="col-lg-6 col-12">
          <div className="loginCont">
            <div className="text-center">
              <img
                src={logo}
                width={100}
                className="text-center mb-4"
                alt="Logo"
              />
              <h4 className="textGradient fs-2 fw-bold text-center mb-1">
                Login
              </h4>
              <p className="text-white fs-5 fw-regular text-center mb-3">
                Please login to your account
              </p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4" controlId="formGroupEmail">
                <Form.Label className="text-white fw-bold fs-6">
                  Email address
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  className="costField"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formGroupPassword">
                <Form.Label className="text-white fw-bold fs-6">
                  Password
                </Form.Label>
                <div className="input-group">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="costField"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    className="btn btn-outline-secondary border-0 rounded-0 passEye"
                    type="button"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
              </Form.Group>

              <div className="d-grid gap-2 mt-2">
                <button type="submit" className="btnStyles py-3 rounded-0">
                  Login
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
