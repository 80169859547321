/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import {
  Form,
  InputGroup,
  Modal,
  Button,
  Toast,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import About from "../img/abt.png";
import { ToastContainer, toast } from "react-toastify";
// import { Toast } from 'react-bootstrap';

const AddEvent = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedLogoUrl, setSelectedLogoUrl] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState(""); // Define toastType state variable

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const showToastMessage = (message, type) => {
    // Accept type parameter
    setToastMessage(message);
    setToastType(type); // Set toastType state
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000); // Adjust the duration as needed
  };

  const handleSaveAndCreate = async (e) => {
    e.preventDefault();
    const form = document.querySelector(".row");
    const isValid = form.checkValidity();

    // Check if all required fields are filled
    const requiredFields = form.querySelectorAll("[required]");
    let allFieldsFilled = true;
    requiredFields.forEach((field) => {
      if (!field.value.trim()) {
        allFieldsFilled = false;
        field.classList.add("is-invalid"); // Mark empty fields as invalid
      }
    });

    if (isValid && allFieldsFilled) {
      setShowConfirmation(true);
    } else {
      // If any required field is empty, show error message
      showToastMessage("Please fill out all required fields.", "error");
      // Also, mark all invalid fields as invalid to display error messages
      requiredFields.forEach((field) => {
        if (!field.value.trim()) {
          field.classList.add("is-invalid");
        }
      });
    }
  };

  const handleProceed = async () => {
    console.log(">>>>");
    setShowConfirmation(false);

    const nameElement = document.getElementById("formGroupName");
    const eventNumberElement = document.getElementById("formGroupEventNumber");
    // const participantsLimitElement = document.getElementById(
    //   "formGroupParticipantsLimit"
    // );
    const coinsElement = document.getElementById("coins");
    const gemsElement = document.getElementById("gems");
    const trackNameElement = document.getElementById("formGroupTrackName");
    const logoImageUrlElement = document.getElementById("logoImageUrl");
    const completionCoinsElement = document.getElementById("completionCoins");
    const completionGemsElement = document.getElementById("completionGems");
    const xpElement = document.getElementById("xp");
    const arabicTrackNameElement = document.getElementById(
      "formGroupTotalAllowedMembers"
    );
    // const skyBoxElement = document.getElementById("skyBox");
    console.log("trackNameElement::: ", trackNameElement);
    if (
      nameElement &&
      eventNumberElement &&
      // participantsLimitElement &&
      coinsElement &&
      gemsElement &&
      trackNameElement &&
      arabicTrackNameElement &&
      // skyBoxElement &&
      logoImageUrlElement &&
      completionCoinsElement &&
      completionGemsElement &&
      xpElement
    ) {
      const eventData = {
        name: nameElement.value,
        eventNumber: parseInt(eventNumberElement.value),
        // participantsLimit: parseInt(participantsLimitElement.value),
        entryFee: {
          coins: parseInt(coinsElement.value),
          gems: parseInt(gemsElement.value),
        },
        trackName: trackNameElement.value,
        arabicTrackName: arabicTrackNameElement.value,
        // skyBox: skyBoxElement.value,
        startTime: startDate.toISOString(),
        endTime: endDate.toISOString(),
        logoUrl: logoImageUrlElement.value,
        eventCompletionReward: {
          coins: parseInt(completionCoinsElement.value),
          gems: parseInt(completionGemsElement.value),
          xp: parseInt(xpElement.value),
        },
        // Add other fields as needed
      };

      try {
        localStorage.setItem("newEvent", JSON.stringify(eventData.eventNumber));
        localStorage.setItem("trackName", eventData.trackName);
        // Make the API call
        const token = localStorage.getItem("x-access-token");
        const response = await fetch(
          "https://api.thunderhorse.ae/api/v1/events",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify(eventData),
          }
        );
        const responseData = await response.json();
        console.log("::: ", responseData);

        if (responseData.code === 400) {
          toast.error(responseData.message);
        } else {
          toast.success("Event Created");
          // Redirect after successful creation
          window.location.href = "/add-event-race";
        }
      } catch (error) {
        console.error("Error creating event:", error);
        // Handle error, maybe show an alert to the user
      }
    } else {
      console.error("Some form elements are missing");
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        // eslint-disable-next-line no-undef
        className={`toast-${toastType} toast-top-right`} // Apply custom classes based on toastType
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      <h4 className="textGradient fs-1 fw-bold text-center mb-3">Add Event</h4>

      <Form className="row">
        <Form.Group className="mb-3 col-md-6" controlId="formGroupName">
          <Form.Label className="text-white fw-bold">
            Name{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Please enter a valid unique event name that displays during
                  the current event.
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-info" />
            </OverlayTrigger>
          </Form.Label>
          <Form.Control type="text" className="costField" required />
        </Form.Group>
        <Form.Group className="mb-3 col-md-6" controlId="formGroupEventNumber">
          <Form.Label className="text-white fw-bold">
            Event Number{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Please input the event number that follows the current event
                  number.
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-info" />
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            type="text"
            className="costField"
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
            required
          />
        </Form.Group>

        {/* Entry Fee */}
        <div className="p-2 my-3">
          <Form.Label className="text-white fw-bold">
            Entry Fee{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Enter the required entry fee to join the event.
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-info" />
            </OverlayTrigger>
          </Form.Label>
          <div className="formFee">
            <div className="row">
              <Form.Group className="mb-3 col-md-6" controlId="coins">
                <Form.Label className="text-white fw-bold">Coins</Form.Label>
                <Form.Control
                  type="text"
                  className="costField"
                  onInput={(e) => {
                    // Allow only numbers by removing non-numeric characters
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 col-md-6" controlId="gems">
                <Form.Label className="text-white fw-bold">Gems</Form.Label>
                <Form.Control
                  type="text"
                  className="costField"
                  onInput={(e) => {
                    // Allow only numbers by removing non-numeric characters
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  required
                />
              </Form.Group>
            </div>
          </div>
        </div>
        {/* Track Name */}
        <Form.Group className="mb-3" controlId="formGroupTrackName">
          <Form.Label className="text-white fw-bold">
            Track Name{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>Select the track name for event races.</Tooltip>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-info" />
            </OverlayTrigger>
          </Form.Label>
          <Form.Select className="costField" required>
            <option value="Meydan">Meydan</option>
            <option value="KingAbdulAziz">King Abdul Aziz</option>
            <option value="JabelAli">Jabel Ali</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGroupTotalAllowedMembers">
          <Form.Label className="text-white fw-bold">
            Arabic Track Name
          </Form.Label>

          <Form.Select className="costField" required>
            <option value="ميدان">ميدان</option>
            <option value="الملك عبد العزيز">الملك عبد العزيز</option>
            <option value="جبل علي">جبل علي</option>
          </Form.Select>
        </Form.Group>

        {/* Start Date */}
        <Form.Group className="mb-3 col-md-6" controlId="formGroupStartDate">
          <Form.Label className="text-white fw-bold">
            Start Date and Time
          </Form.Label>
          <div className="clearfix"></div>
          <InputGroup className="position-relative">
            <InputGroup.Text className="position-absolute calenIcon">
              <FontAwesomeIcon icon={faCalendarDays} className="fs-2" />
            </InputGroup.Text>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
              className="costField d-block calnder"
              required
            />
          </InputGroup>
        </Form.Group>
        {/* End Date */}
        <Form.Group className="mb-3 col-md-6" controlId="formGroupEndDate">
          <Form.Label className="text-white fw-bold">
            End Date and Time
          </Form.Label>
          <div className="clearfix"></div>
          <InputGroup className="position-relative">
            <InputGroup.Text className="position-absolute calenIcon">
              <FontAwesomeIcon icon={faCalendarDays} className="fs-2" />
            </InputGroup.Text>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
              className="costField d-block calnder"
              required
            />
          </InputGroup>
        </Form.Group>
        {/* Additional Instructions */}
        <p className="text-white">
          <img src={About} width={30} className="me-2" alt="info" />
          Start date & time and End date & time must be according to north
          virginia time zone
        </p>
        {/* Logo Image URL */}

        <Form.Group className="mb-3" controlId="logoImageUrl">
          <Form.Label className="text-white fw-bold">
            Logo image{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Please select the image of the event logo to be displayed.
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-info" />
            </OverlayTrigger>{" "}
          </Form.Label>
          <Form.Select
            className="costField"
            required
            onChange={(e) => {
              // Update selectedLogoUrl state when a new option is selected
              setSelectedLogoUrl(e.target.value);
            }}
          >
            <option value="">Select an image</option>
            <option value="https://horseracings3bucket.s3.eu-north-1.amazonaws.com/events/eventlogo/1.png">
              Image 1
            </option>
            <option value="https://horseracings3bucket.s3.eu-north-1.amazonaws.com/events/eventlogo/2.png">
              Image 2
            </option>
            <option value="https://horseracings3bucket.s3.eu-north-1.amazonaws.com/events/eventlogo/3.png">
              Image 3
            </option>
            <option value="https://horseracings3bucket.s3.eu-north-1.amazonaws.com/events/eventlogo/4.png">
              Image 4
            </option>
            <option value="https://horseracings3bucket.s3.eu-north-1.amazonaws.com/events/eventlogo/5.png">
              Image 5
            </option>
            <option value="https://horseracings3bucket.s3.eu-north-1.amazonaws.com/events/eventlogo/6.png">
              Image 6
            </option>
            <option value="https://horseracings3bucket.s3.eu-north-1.amazonaws.com/events/eventlogo/7.png">
              Image 7
            </option>
          </Form.Select>
        </Form.Group>
        {/* Image preview */}
        {selectedLogoUrl && (
          <div className="mb-3">
            <h6 className="text-white fw-bold">Image Preview</h6>
            <img
              src={selectedLogoUrl}
              alt="Logo Preview"
              className="img-fluid"
            />
          </div>
        )}
        {/* Event Completion Reward */}
        <div className="p-2 my-3">
          <Form.Label className="text-white fw-bold">
            Event Completion Reward{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Please enter the amount of coins , gems and xp rewarded upon
                  completing the event
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ms-2 text-info" />
            </OverlayTrigger>{" "}
          </Form.Label>
          <div className="formFee">
            <div className="row">
              <Form.Group className="mb-3 col-md-4" controlId="completionCoins">
                <Form.Label className="text-white fw-bold">Coins</Form.Label>
                <Form.Control
                  type="text"
                  className="costField"
                  onInput={(e) => {
                    // Allow only numbers by removing non-numeric characters
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 col-md-4" controlId="completionGems">
                <Form.Label className="text-white fw-bold">Gems</Form.Label>
                <Form.Control
                  type="text"
                  className="costField"
                  onInput={(e) => {
                    // Allow only numbers by removing non-numeric characters
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 col-md-4" controlId="xp">
                <Form.Label className="text-white fw-bold">Xp</Form.Label>
                <Form.Control
                  type="text"
                  className="costField"
                  onInput={(e) => {
                    // Allow only numbers by removing non-numeric characters
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  required
                />
              </Form.Group>
            </div>
          </div>
        </div>
        {/* Save & Create Button */}
        <div className="d-flex justify-content-center my-5">
          <div className="d-grid gap-2 col-md-5">
            <button
              onClick={handleSaveAndCreate}
              className="btnStyles text-black fs-4 fw-bold rounded-0 px-5 py-3 border-0 text-center text-decoration-none"
            >
              Save & Create Event Races
            </button>
          </div>
        </div>
        {/* Confirmation Modal */}
        <Modal show={showConfirmation} onHide={handleConfirmationClose}>
          <Modal.Header>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to create a live event? This action will
            schedule a live event to be broadcasted to your audience. Please
            ensure all necessary details, including date, time, and content, are
            correctly set before proceeding.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleConfirmationClose}
              className="btnStyles text-black fs-6 fw-bold rounded-0 px-3 py-2 border-0 text-center text-decoration-none"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleProceed}
              className="btnStyles text-black fs-6 fw-bold rounded-0 px-3 py-2 border-0 text-center text-decoration-none"
            >
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </div>
  );
};

export default AddEvent;
