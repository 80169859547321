import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareCheck,
  faSquareXmark,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { format, parseISO } from "date-fns";
import Modal from "react-bootstrap/Modal"; // Import Modal component
import Button from "react-bootstrap/Button"; // Import Button component

const ManageEvent = () => {
  const [events, setEvents] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null); // State variable to store the event number to be deleted
  const token = localStorage.getItem("x-access-token");

  useEffect(() => {
    fetch("https://api.thunderhorse.ae/api/v1/events", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Update state with formatted data
        setEvents(
          data.data.events.map((event) => ({
            ...event,
            startTime: format(parseISO(event.startTime), "dd-MM-yyyy HH:mm"),
            endTime: format(parseISO(event.endTime), "dd-MM-yyyy HH:mm"),
            createdAt: format(parseISO(event.createdAt), "dd-MM-yyyy HH:mm"),
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [token]);

  const handleDeleteEvent = async (eventNumber) => {
    // Set the event number to be deleted
    setEventToDelete(eventNumber);
    // Show the confirmation modal
    setShowConfirmation(true);
  };

  const handleConfirmationClose = () => {
    // Reset the event number and hide the confirmation modal
    setEventToDelete(null);
    setShowConfirmation(false);
  };

  const handleProceed = async () => {
    try {
      const response = await fetch(
        `https://api.thunderhorse.ae/api/v1/events/${eventToDelete}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      );
      if (response.ok) {
        // If deletion is successful, update the events list by filtering out the deleted event
        setEvents(
          events.filter((event) => event.eventNumber !== eventToDelete)
        );
        // Hide the confirmation modal
        setShowConfirmation(false);
      } else {
        console.error("Failed to delete event");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  return (
    <>
      <div className="tableCont p-3">
        <div className="racingEvent">
          <div className="items">
            <Table size="lg" className="tablee" striped bordered hover>
              <thead>
                <tr>
                  <th>Event No.</th>
                  <th>Event Name</th>
                  <th>No of Races</th>
                  <th>Started at</th>
                  <th>Ends at</th>
                  <th>Created at</th>
                  <th>Created by</th>
                  <th>Is Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {events.map((event) => (
                  <tr key={event.eventNumber}>
                    <td>{event.eventNumber}</td>
                    <td>{event.name}</td>
                    <td>{event.races.length}</td>
                    <td>{event.startTime}</td>
                    <td>{event.endTime}</td>
                    <td>{event.createdAt}</td>
                    <td>{event.createdBy}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={event.isActive ? faSquareCheck : faSquareXmark}
                        className={
                          event.isActive
                            ? "text-success fs-4"
                            : "text-danger fs-4"
                        }
                      />
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="text-danger cursor-pointer"
                        onClick={() => handleDeleteEvent(event.eventNumber)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {/* Confirmation Modal */}
      <Modal show={showConfirmation} onHide={handleConfirmationClose}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this event?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleConfirmationClose}
            className="btnStyles text-black fs-6 fw-bold rounded-0 px-3 py-2 border-0 text-center text-decoration-none"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleProceed}
            className="btnStyles text-black fs-6 fw-bold rounded-0 px-3 py-2 border-0 text-center text-decoration-none"
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageEvent;
