 
import './App.css';
import LoginPage from './admin/login/LoginPage';
// import GlossaryDetails from './pages/GlossaryDetails';
// import HomePage from "./pages/HomePage"  
import DashboardIndex from './admin/dashboard/DashboardIndex'; 

import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddEventPage from './admin/addeventpage/AddEventPage';
import EventRace from "./admin/eventracepage/EventRace"
function App() {
  return (
    <BrowserRouter> 
      <Routes>  
       <Route path="dashboard" element={<DashboardIndex />}/> 
        <Route path="add-event" element={<AddEventPage />}/> 
        <Route path="add-event-race" element={<EventRace />}/> 
        {/*  <Route path="*" element={<HomePage />} />
        <Route path="/glossary-details/:id" element={<GlossaryDetails />} /> */}
        <Route path="/" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
